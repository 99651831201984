<template>
	
	<section
		id="productList"
		class="productsList"
		:class="hasFeaturedProducts === false ? 'notFeaturedProducts' : ''"
	>

	<transition appear enter-active-class="animated slideInDown delay-2s">
					<div class="descriptionCollectionContent " v-for="product in products">
						<div v-for="collection in product.filhos"
							v-if="collection.id==selectedCollection">
							<div class="categoria">{{ product.title }}</div>
							<div class="titulo1">{{ collection.firstname }}</div>
							<div class="titulo2">{{ collection.secondname }}</div>
							<div class="colecao" v-html="collection.descricao"> </div>
										
										
										
						</div>
					</div>

				</transition>
		<div class="container">
			<div class="row" id="totop">
				<transition
					appear
					enter-active-class="animated slideInDown"
					leave-active-class="animated slideOutDown"
				>
					<!-- <h1 class="pageTitleh2 h2" v-if="hasFeaturedProducts">
						<slot></slot>
					</h1>
					<h1 class="pageTitle" v-if="!hasFeaturedProducts">
						{{ categoryName1 }}
						<span>{{ categoryName2 }}</span>
					</h1> -->
					
				</transition>
				
				

				<transition appear enter-active-class="animated slideInUp delay-2s">
					<div class="filters col-md-3" >
						  <!-- <ul class="categoryMenu" ref="menu" key="filterCategory" >
							<li
								v-for="(product, index) in products"
								:key="product.id"
								:class="
								product.id == selectedCategory
									? 'filters__item active'
									: 'filters__item'
								"
							>
								<router-link
									:to="{
										path:
											'/bloco-b/category/' +
											product.id
										}"
									v-scroll-to="'header'"
								>{{ product.title }}</router-link>
							</li>							
						</ul>   -->
						<div class="contentorFiltros">
						<div class="tituloCollections" v-html="$t('collections')"></div>
						<ul ref="menu" key="filterCollection" :class="'cat-' + product.id + ' collectionMenu'" v-for="(product, index) in products">
							
							<li
								class="filters__item"
								v-for="collection in product.filhos"
								:key="collection.id"
								:class="
									collection.id == selectedCollection
										? 'filters__item active'
										: 'filters__item'
									"
							>
								<a
									href="javascript:void(0);"
									v-scroll-to="'header'"
									@click="filterByCollection(collection.id)"
								>{{ collection.title }}</a>

								<!-- <a
									:href="'/bloco-b/category/' + selectedCategory +'/collection/' + collection.id"
									v-scroll-to="'header'"
									@click="filterByCollection(collection.id)"
								>{{ collection.title }}</a>
								<router-link 					
							
									:to="{
										path:
										'/bloco-b/category/' +
										selectedCategory +
										'/collection/' +
										collection.id
									}"
									:key="collection.id"
									
								>
								{{ collection.title }}
								</router-link> -->
							</li>
						</ul> 
					
						<ul class="colorFilters">
							<div class="tituloCollections" v-html="$t('artistas')"></div>
							<li v-for="color in availableArtists" :key="color.id">

								<!-- <a
									href="javascript:void(0);"
									v-scroll-to="'header'"
									@click="filterByColor(color.id)"
								>{{ color.name }}</a> -->

								 <a
									href="javascript:void(0);"
									@click="filterByArtist(color.id)"
									:class="getArtistStatus(color.id)"
								> 
									<span class="color" :style="'background-color:#fff' "></span>
									<span class="name">{{ color.name }}</span>
								</a> 
							</li>

							<a class="clear_filters" @click="unfilterByArtist()" href="javascript:void(0);" v-html="$t('clear_filters')"></a>
						</ul> 
						<template v-for="(product, index) in products">

						
						<template v-for="(collection, index) in product.filhos" v-if="collection.id==selectedCollection">
							<ul class="colorFilters">
							<div class="tituloCollections" v-html="$t('pecas')"></div>
							<li v-for="(child, index) in collection.filhos" :key="child.id">

								<!-- <a
									href="javascript:void(0);"
									v-scroll-to="'header'"
									@click="filterByColor(color.id)"
								>{{ color.name }}</a> -->

								 <a
									href="javascript:void(0);"
									@click="filterByPart(child.id)"
									:class="getPartStatus(child.id)"
								> 
									<span class="color" :style="'background-color:#fff' "></span>
									<span class="name">{{ child.title }}</span>
								</a> 
							</li>

							<a class="clear_filters" @click="unfilterByPart()" href="javascript:void(0);" v-html="$t('clear_filters')"></a>
						</ul> 
						</template>
					</template>

					</div>
					</div>
				</transition>
			
			
			
				<transition appear enter-active-class="animated slideInUp delay-2s">
				<div
					class="col-12 col-md-9 productsContainer row"
					:class="hasFeaturedProducts === true ? 'featuredCenter' : ''"
					itemscope
					itemtype="http://schema.org/ItemList"
					v-for="(product, index) in products"
					
				>
				<template class="row" v-for="(collection, index) in product.filhos" v-if="collection.id==selectedCollection">

					<link
						itemprop="url"
						:href="'/parts/category/' + selectedCategory + '/collection/' + selectedCollection"
						
					/>
					<span v-if="hasFeaturedProducts" itemprop="numberOfItems" class="d-none">{{ productsPerPage }}</span>

						
					<!-- <transition-group appear enter-active-class="animated slideInUp delay" tag="div" class="row" > -->
						<template class="row" v-if="!selectedArtists.length && !selectedParts.length">
							<!-- <button
								@click="showInfo()"
								tag="button"
								class="buttons cartButton"
								v-bind:aria-label="$t('button-arialabel-open-cart')"
							>
								
							</button> -->
							<template   v-for="(child, index) in collection.filhos">
								<template   v-for="(child2, index) in child.filhos">
							<router-link 
							class="rellaxProduct product col-12 col-lg-6 col-xl-4 "
							itemprop="itemListElement"
							itemscope
							itemtype="http://schema.org/Product"
							
							
							:to="{
								path:
								'/parts/category/' +
								selectedCategory +
								'/collection/' +
								selectedCollection +
								'/id/' +
								child2.id +
								'/name/' +
								child2.title,
							}"
							:key="child2.id"
							:data-rellax-speed="getDataSpeed(child2.id)"
						>
							
							<meta itemprop="position" :content="index" />
							<a
								:href="
									'/parts/category/' +
										selectedCategory +
										'/collection/' +
										selectedCollection +
										'/id/' +
										child2.id +
										'/name/' +
										child2.title
									"
								itemprop="url"
							></a>
							 <meta itemprop="sku" :content="child2.id" /> 

							 <div itemprop="offers" itemtype="https://schema.org/Offer" itemscope>
								<span itemprop="priceCurrency" content="EUR"></span>
								<link itemprop="availability" href="https://schema.org/InStock" />
							</div>

							 <div class="containerImage">
								<img
									:src="child2.imagem_da_peca"
									class="productImage"
									width="300"
									height="300"
									:alt="child2.title"
									itemprop="image"
								/>
							</div>
							<div class="productName_peca">
						
								{{ child2.title }}
							</div>
							<div class="artistName_peca">
						
								{{ child2.artist }}
							</div>
							 <p class="categoryName" v-if="hasFeaturedProducts">{{ getCategory(collection).title }}</p>  
						
							
							</router-link>
						</template>
					</template>
				</template>

					<template class="row" v-else-if="selectedArtists.length && !selectedParts.length" >
						<template   v-for="(child, index) in collection.filhos">
								<template   v-for="(child2, index) in child.filhos">
									
									<template  v-for="(artist, index) in selectedArtists">
										
										<div v-if="artist==child2.artistid" class="rellaxProduct product col-12 col-lg-6 col-xl-4" :data-rellax-speed="getDataSpeed(index)" >
									
									
										
							<router-link 
							class=" "
							itemprop="itemListElement"
							itemscope
							itemtype="http://schema.org/Product"							
							
							:to="{
								path:
								'/parts/category/' +
								selectedCategory +
								'/collection/' +
								selectedCollection +
								'/id/' +
								child2.id +
								'/name/' +
								child2.title
							}"
							:key="child2.id"
							
						>
							
							<meta itemprop="position" :content="index" />
							<a
								:href="
									'/parts/category/' +
										selectedCategory +
										'/collection/' +
										selectedCollection +
										'/id/' +
										child2.id +
										'/name/' +
										child2.title
									"
								itemprop="url"
							></a>
							 <meta itemprop="sku" :content="child2.id" /> 

							 <div itemprop="offers" itemtype="https://schema.org/Offer" itemscope>
								<span itemprop="priceCurrency" content="EUR"></span>
								<link itemprop="availability" href="https://schema.org/InStock" />
							</div>

							 <div class="containerImage">
								<img
									:src="child2.imagem_da_peca"
									class="productImage"
									width="300"
									height="300"
									:alt="child2.title"
									itemprop="image"
								/>
							</div>
							
							<div class="productName_peca">
						
								{{ child2.title }}
							</div>
							<div class="artistName_peca">
						
								{{ child2.artist }}
							</div>
							 <p class="categoryName" v-if="hasFeaturedProducts">{{ getCategory(collection).title }}</p>  
						
							
							</router-link>
					
								</div>
							</template >
						</template >
						</template >
					</template >

					<template class="row" v-else-if="!selectedArtists.length && selectedParts.length" >
							
							<template   v-for="(child, index) in collection.filhos">
									<template   v-for="(child2, index) in child.filhos">
										
										<template  v-for="(part, index) in selectedParts">
											
											<div v-if="part==child2.parent" class="rellaxProduct product col-12 col-lg-6 col-xl-4" :data-rellax-speed="getDataSpeed(index)" >
										
										
											
								<router-link 
								class=" "
								itemprop="itemListElement"
								itemscope
								itemtype="http://schema.org/Product"							
								
								:to="{
									path:
									'/parts/category/' +
									selectedCategory +
									'/collection/' +
									selectedCollection +
									'/id/' +
									child2.id +
									'/name/' +
									child2.title,
								}"
								:key="child2.id"
								
							>
								
								<meta itemprop="position" :content="index" />
								<a
									:href="
										'/parts/category/' +
											selectedCategory +
											'/collection/' +
											selectedCollection +
											'/id/' +
											child2.id +
											'/name/' +
											child2.title
										"
									itemprop="url"
								></a>
								 <meta itemprop="sku" :content="child2.id" /> 
	
								 <div itemprop="offers" itemtype="https://schema.org/Offer" itemscope>
									<span itemprop="priceCurrency" content="EUR"></span>
									<link itemprop="availability" href="https://schema.org/InStock" />
								</div>
	
								 <div class="containerImage">
									<img
										:src="child2.imagem_da_peca"
										class="productImage"
										width="300"
										height="300"
										:alt="child2.title"
										itemprop="image"
									/>
								</div>
								
								<div class="productName_peca">
							
									{{ child2.title }}
								</div>
								<div class="artistName_peca">
							
									{{ child2.artist }}
								</div>
								 <p class="categoryName" v-if="hasFeaturedProducts">{{ getCategory(collection).title }}</p>  
							
								
								</router-link>
						
									</div>
								</template >
							</template >
							</template >
						</template >



						<template class="row" v-else-if="selectedArtists.length && selectedParts.length" >
							
							<template   v-for="(child, index) in collection.filhos">
								<template   v-for="(child2, index) in child.filhos">
									
									<template  v-for="(artist, index) in selectedArtists">
										<template  v-for="(part, index) in selectedParts">
										<div v-if="artist==child2.artistid && part==child2.parent" class="rellaxProduct product col-12 col-lg-6 col-xl-4" :data-rellax-speed="getDataSpeed(index)" >
									
									
										
												<router-link 
												class=" "
												itemprop="itemListElement"
												itemscope
												itemtype="http://schema.org/Product"							
												
												:to="{
													path:
													'/parts/category/' +
													selectedCategory +
													'/collection/' +
													selectedCollection +
													'/id/' +
													child2.id +
													'/name/' +
													child2.title,
												}"
												:key="child2.id"
												
											>
												
												<meta itemprop="position" :content="index" />
												<a
													:href="
														'/parts/category/' +
															selectedCategory +
															'/collection/' +
															selectedCollection +
															'/id/' +
															child2.id +
															'/name/' +
															child2.title
														"
													itemprop="url"
												></a>
												<meta itemprop="sku" :content="child2.id" /> 

												<div itemprop="offers" itemtype="https://schema.org/Offer" itemscope>
													<span itemprop="priceCurrency" content="EUR"></span>
													<link itemprop="availability" href="https://schema.org/InStock" />
												</div>

												<div class="containerImage">
													<img
														:src="child2.imagem_da_peca"
														class="productImage"
														width="300"
														height="300"
														:alt="child2.title"
														itemprop="image"
													/>
												</div>
												
												<div class="productName_peca">
											
													{{ child2.title }}
												</div>
												<div class="artistName_peca">
											
													{{ child2.artist }}
												</div>
												<p class="categoryName" v-if="hasFeaturedProducts">{{ getCategory(collection).title }}</p>  
											
												
												</router-link>
										
													</div>
												</template >
											</template >
										</template>
											</template >
						</template >



					</template >
				
					<!-- </transition-group> -->
				
			</div>
		</transition>	
			</div>
		</div>
		<transition
			enter-active-class="animated slideInRight faster"
			leave-active-class="animated slideOutRight faster"
		>
			<ModalLogin v-if="showLoginForm" @close="showLoginForm = false"></ModalLogin>
		</transition>
	</section>

	
</template>

<script>
	import ModalLogin from "./subcomponents/ModalLogin";
	import { mapState, mapGetters } from "vuex";
	export default {
		name: "Listpage",
		components:{
			ModalLogin
		},
		computed: {
			...mapState(["btn", "login"]),
			...mapGetters(["cartSize", "cartTotalAmount", "isLoggedIn"])
		},
		data() {
			return {
				products: [],
				collections: [],
				// categories: [],
				// collections: [],
				availableArtists: [],
				// rawColors: [],
				rawProducts: [],
				hasFeaturedProducts: false,
				productsPerPage: 12,
				active: "",
				selectedCategory: null,
				selectedCollection: null,
				selectedColors: [],
				selectedColor: [],

				selectedParts: [],
				selectedPart: [],

				selectedArtist: [],
				selectedArtists: [],
				// categoryName1: "",
				// categoryName2: "",
				rellax: null,
				
				showModal: false,
				showLoginForm: false,
				redirectURL: null
			
				};
		},
		beforeMount() {
			if (this.$route.params) {
				this.selectedCategory = this.$route.params.category;
				this.selectedCollection = this.$route.params.collection;
				
				
			}
			
		},
		ready(){
			
		},
		mounted() {
			// this.load();
			
			this.$http
				.get(
					"https://www.bstone.pt/webservices/" + this.$i18n.locale + "/pecas2"
				)
				.then(response => {
					this.products = response.data;
					this.availableArtists = response.data.artists;

					// console.log(this.availableArtists);
					// this.gallery1 = response.data.gallery1;
					// this.gallery2 = response.data.gallery2;
					// this.slidergallery = response.data.slidergallery;

					// this.hasJsonData = true;
					// this.notifyFinishLoad();

					// this.applyFilter();


						
					this.$nextTick(() => {
            			    this.rellax = new Rellax('.rellaxProduct', { speed: 1 })
			        })

					this.$eventBus.$emit("pageFinishLoad", true);				
					
					
				});
				this.stickymenu();
		},
		watch: {
			categories: function(newVal, oldVal) {
				if (this.hasFeaturedProducts) return "";
				// this.categoryName1 = this.categories[this.selectedCategory].splitName1;
				// this.categoryName2 = this.categories[this.selectedCategory].splitName2;
			},
			$route(to, from) {
				if (this.$route.params) {
					this.selectedCategory = this.$route.params.category;
					this.selectedCollection = this.$route.params.collection;
					this.selectedColor = this.$route.params.color;
					this.selectedPart = this.$route.params.part;

					this.selectedArtist = this.$route.params.artist;
					
				}
				this.rawProducts = [];
				
				// this.load();
// console.log(this.selectedColor);
			}
		},
		methods: {
			stickymenu(){
				/*var stickySidebar = $('#totop').offset().top;

				$(window).scroll(function() {  
					if ($(window).scrollTop() > stickySidebar) {
						$('.contentorFiltros').addClass('affix');
					}
					else {
						$('.contentorFiltros').removeClass('affix');
					}  
				});*/
				$(window).scroll(function() {
					console.log($(".descriptionCollectionContent")[0].getBoundingClientRect());
					if ($(".descriptionCollectionContent")[0].getBoundingClientRect().bottom < 0){
						$('.contentorFiltros').addClass('affix');
					}else{
						$('.contentorFiltros').removeClass('affix');
					}
						// alert ("i'm out :3");
				});
			},
			showInfo() {
				if (!this.isLoggedIn) {
					this.showLoginForm = true;
					this.redirectURL = "/";
				} else {
					if (this.cartSize > 0) this.$route.params;
				}
			},
			getCategory(product) {
				return this.categories.filter(item => item.id == product.category)[0];
			},
			getDataSpeed(index) {
				if (this.hasFeaturedProducts) return 0;
				return index % 3 == 1 ? 2 : -2;
			},
			getArtistStatus(id) {
				
				

				return this.selectedArtists.filter(item => item == id).length > 0
					? "active"
					: "inactive";
			},
			getPartStatus(id) {
				
				

				return this.selectedParts.filter(item => item == id).length > 0
					? "active"
					: "inactive";
			},
			// parseObject: function(source, destination, featuredOnly) {
			// 	for (var i = 0; i < source.length; i++) {
			// 		let obj = source[i];
			// 		let fullPath = obj.imgURL;
			// 		//obj.imgURL = fullPath;
			// 		if (featuredOnly && !obj.featured) continue;
			// 		destination.push(obj);
			// 		this.rawProducts.push(obj);
			// 	}
			// },
			// filterByCategory(id) {
			// 	this.selectedCategory = id;
			// 	this.selectedCollection = this.collections[0].id; // selects the first collection by default
			// 	this.selectedColors = [];
			// 	this.applyFilter();
			// },
			filterByCollection(id) {
				this.selectedCollection = id;
				this.selectedColors = [];
				// this.applyFilter();
			},
			filterByArtist(id) {
				this.$scrollTo("#totop");
				this.selectedArtist = id;
				const index = this.selectedArtists.indexOf(id);

				

				if (index > -1) {
					this.selectedArtists = this.selectedArtists.filter(item => item !== id);
				} else {
					this.selectedArtists.push(id);
				}
				// console.log(this.selectedArtist)
				
				// this.applyFilter();
			},
			unfilterByArtist() {
				this.$scrollTo("#totop");
				this.selectedArtists.splice(0);
			},
			filterByPart(id) {
				this.$scrollTo("#totop");
				this.selectedPart = id;
				const index = this.selectedParts.indexOf(id);

				

				if (index > -1) {
					this.selectedParts = this.selectedParts.filter(item => item !== id);
				} else {
					this.selectedParts.push(id);
				}
				// console.log(this.selectedPart)
				
				// this.applyFilter();
			},
			unfilterByPart() {
				this.$scrollTo("#totop");
				this.selectedParts.splice(0);
			},
			// load() {
			// 	this.$http
			// 		.get(
			// 			"https://www.bstone.pt/webservices/" +
			// 				this.$i18n.locale +
			// 				"/produtos2"
			// 		)
			// 		.then(response => {
			// 			// this.collections = response.data.collections;
			// 			// this.categories = response.data.categories;
			// 			// this.rawColors = response.data.colors;

			// 			this.productsPerPage = this.$parent.productsPerPage;
			// 			this.hasFeaturedProducts = this.$parent.hasFeaturedProducts;

			// 			if (this.hasFeaturedProducts) {
			// 				this.parseObject(
			// 					response.data,
			// 					this.products,
			// 					this.hasFeaturedProducts
			// 				);
			// 				return;
			// 			}

			// 			if (!this.$route.params) {
			// 				this.selectedCategory = this.categories[0].id;
			// 				this.selectedCollection = this.collections[0].id;
			// 			}

			// 			this.parseObject(
			// 				response.data,
			// 				this.products,
			// 				this.hasFeaturedProducts
			// 			);

			// 			this.applyFilter();
						
			// 			this.$nextTick(() => {
            // 				    this.rellax = new Rellax('.rellaxProduct', { speed: 1 })
			//             })

			//             this.$eventBus.$emit("pageFinishLoad", true);
			// 		});
			// },
			applyFilter() {
				// this.categoryName1 = this.categories[this.selectedCategory].splitName1;
				// this.categoryName2 = this.categories[this.selectedCategory].splitName2;

				// this.products = [];
				
				var filteredColors = [];

				var collection=this.selectedCollection;
				// console.log(this.selectedColor);
				
				for (var i = 0; i < this.products.length; i++) {
					
				}

				// if(this.selectedColor>=0){
				// 	if(this.product.length>0){
				// 		console.log(0)
				// 	}
				// }
				this.$nextTick(() => {
            			    this.rellax = new Rellax('.rellaxProduct', { speed: 1 })
			        })

				// for (var i = 0; i < this.rawProducts.length; i++) {
				// 	let obj = this.rawProducts[i];

				// 	const matchesCategory =
				// 		this.selectedCategory == null ||
				// 		obj.category == this.selectedCategory;
				// 	const matchesCollection =
				// 		this.selectedCollection == null ||
				// 		obj.collection == this.selectedCollection;
				// 	if (!matchesCollection || !matchesCategory) continue;

				// 	for (var k = 0; k < obj.colors.length; k++) {
				// 		filteredColors.push(obj.colors[k]);
				// 	}

				// 	if (this.selectedColors.length > 0) {
				// 		for (var j = 0; j < obj.colors.length; j++) {
				// 			if (this.selectedColors.indexOf(obj.colors[j]) > -1) {
				// 				// color found !!!!
				// 				this.products.push(obj);
				// 				break;
				// 			}
				// 		}
				// 	} else {
				// 		this.products.push(obj);
				// 	} // Filter array is empty

				// 	this.availableArtists = this.rawColors.filter(
				// 		color => filteredColors.indexOf(color.id) > -1
				// 	);
				// }

			}
		}
	};
	


</script>
<style lang="scss">
	.productsList .affix{
		// overflow: hidden;
		position: fixed;
		top: 150px;
		overflow-y: scroll;
    	height: 70vh;
	}
	a .productName_peca{
		width: 260px;
		margin: 0 auto;
		font-family: 'Oswald';
		font-weight: bold;
		color: black;
		text-transform: uppercase;
		font-size: 16pt;
    	padding-top: 10px;
	}

	.artistName_peca{
		width: 260px;
		margin: 0 auto;
		color: black;
		font-family: 'Ubuntu';
		padding-top: 10px;
		font-size: 18px;
	}
	.productsList.notFeaturedProducts{
		// overflow: hidden;
	}
	.titulo1,.titulo2{
		font-family: "Noe Display", serif;
		font-size: 40px;
	}
	.titulo2{
		line-height: 0.5;
	}
	.colecao p{
		margin-top: 30px;
    	font-size: 22px;
	}
	
	.filtro_cor{
		list-style: none;
		position: absolute;
		margin: 0;
		margin-top: 350px;
		padding: 0;
		z-index: 1;
	}
	.filtro_cor a span.color {
    width: 22px;
    height: 22px;
    display: inline-flex;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    // will-change: transform,background-color;
    // transition: transform .5s ease,background-color .3s ease;
	}
	.descriptionCollectionContent{
		background-color: white;
		padding-top: 60px;
		padding-left: 25%;
		padding-right: 25%;
    	z-index: 0;
		padding-bottom: 5%;
		margin-top: -22px;
		-webkit-transition: all 0.5s ease 0.2s;
			-moz-transition: all 0.5s ease 0.2s;
			-o-transition: all 0.5s ease 0.2s;
			transition: all 0.5s ease 0.2s;
	}
	.categoria{
		color: #c47c5a;
		font-size: 20px;
		letter-spacing: 4px;
		text-transform: uppercase;
		font-family: 'Oswald', sans-serif;
		padding-bottom: 10px;
	}
	.colecao {
		font-size: 22px;
		padding-top: 10px;
		
	}
	.tituloCollections{
		color:#333333;
		font-family: 'Oswald';
		letter-spacing: 2.4px;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 24px;
		margin-bottom: 5%;
	}
	.clear_filters{
		font-size: 16px;
		text-transform: uppercase;
		color: #969696;
		font-family: 'Oswald';
	}
	.clear_filters:hover{
		color: #333;
		text-decoration: underline;
	}
	.collectionMenu li a::before{
		display: none;
	}




	.productsList {
		// background-color: white;
		min-height: 100vh;
		padding-top: 50px;
		margin-bottom: 13%;

		&.notFeaturedProducts {
			padding-top: 145px;
			// margin-bottom: 25%;

			.productsContainer {
				// margin-top: 300px;
				margin-top: 150px;
				// padding-left: 25%;
				z-index: 0;
			}

			h1.pageTitle {
				position: fixed;
				margin-left: 0;
				font-size: 10rem;
				//z-index: 2;
			}
		}

		.productsContainer.featuredCenter {
			margin: 0 auto;
			max-width: 1100px;
		}

		.filters {
			margin-top: 150px;
			position: relative;
			z-index: 1;
			-webkit-transition: all 0.5s ease 0.2s;
			-moz-transition: all 0.5s ease 0.2s;
			-o-transition: all 0.5s ease 0.2s;
			transition: all 0.5s ease 0.2s;

			.categoryMenu,
			.collectionMenu,
			.colorFilters {
				list-style: none;
				// position: fixed;
				// position: absolute;
				// top: 39vh; //450px;
				
				margin: 0;
				// margin-top: 150px;
				padding: 0;
				z-index: 1;
			}
			.colorFilters{
				margin-top: 90px;
			}
			.categoryMenu {
				li {
					a {
						display: block;
						font-family: "Oswald", sans-serif;
						font-size: 1.25rem;
						letter-spacing: 1px;
						font-weight: 400 !important;
						color: #333;
						line-height: 2rem;
						text-transform: uppercase;
						margin: 10px 0;
						will-change: color, margin;
						-webkit-transition: color 0.5s ease, margin 0.5s ease;
						-moz-transition: color 0.5s ease, margin 0.5s ease;
						-o-transition: color 0.5s ease, margin 0.5s ease;
						transition: color 0.5s ease, margin 0.5s ease;
					}

					&:hover {
						a {
							color: #b7b7b7;
						}
					}

					&.active {
						a {
							margin: 10px 0 110px;
							color: #b7b7b7;
						}
					}
				}
			}

			.collectionMenu {
				will-change: top;
				-webkit-transition: top 0.5s ease;
				-moz-transition: top 0.5s ease;
				-o-transition: top 0.5s ease;
				transition: top 0.5s ease;

				&.cat-0 {
					top: 44vh; //500px;
				}
				&.cat-1 {
					top: 49vh; //540px;
				}
				&.cat-2 {
					top: 54vh; //580px;
				}

				li {
					a {
						display: block;
						position: relative;
						font-family: "Oswald", sans-serif;
						// font-size: 1rem;
						font-weight: 400 !important;
						color: #333;
						line-height: 1.5rem;
						text-transform: uppercase;
						margin: 10px 0;
						// padding-left: 60px;
						font-weight: 100;
						font-size: 20px;
						

						&:before {
							position: absolute;
							top: 50%;
							left: 0;
							content: "";
							width: 30px;
							height: 1px;
							background-color: #333;
							will-change: width, background-color;
							-webkit-transition: width 0.5s ease, background-color 0.3s ease;
							-moz-transition: width 0.5s ease, background-color 0.3s ease;
							-o-transition: width 0.5s ease, background-color 0.3s ease;
							transition: width 0.5s ease, background-color 0.3s ease;
						}
					}

					&.active a,
					& > a:hover {
						color: #c47c5a;
						font-weight: 400;

						&:before {
							background-color: #c47c5a;
							width: 43px;
						}
					}
				}
			}

			.colorFilters {
				top: 200px; //750px;
				// bottom: 5vh;
				z-index: 0;
				// margin-left: 5px;

				a {
					height: 40px;
					display: flex;
					align-items: center;

					span.name {
						font-family: "Oswald", sans-serif;
						font-size: 18px;
						font-weight: 200;
						color: #6a6a6a;
						padding-left: 15px;
						position: relative;
						will-change: padding, color;
						-webkit-transition: color 0.3s ease, padding 0.5s ease;
						-moz-transition: color 0.3s ease, padding 0.5s ease;
						-o-transition: color 0.3s ease, padding 0.5s ease;
						transition: color 0.3s ease, padding 0.5s ease;

						&:before {
							position: absolute;
							top: 15px;
							left: 10px;
							content: "";
							width: 0;
							height: 1px;
							background-color: #6a6a6a;
							will-change: width, background-color;
							-webkit-transition: width 0.5s ease, background-color 0.3s ease;
							-moz-transition: width 0.5s ease, background-color 0.3s ease;
							-o-transition: width 0.5s ease, background-color 0.3s ease;
							transition: width 0.5s ease, background-color 0.3s ease;
						}
					}

					span.color {
						width: 22px;
						height: 22px;
						display: inline-flex;
						-webkit-transform: rotate(45deg);
						-ms-transform: rotate(45deg);
						transform: rotate(45deg);
						transform-origin: 50% 50%;
						will-change: transform, background-color;
						-webkit-transition: transform 0.5s ease, background-color 0.3s ease;
						-moz-transition: transform 0.5s ease, background-color 0.3s ease;
						-o-transition: transform 0.5s ease, background-color 0.3s ease;
						transition: transform 0.5s ease, background-color 0.3s ease;
					}

					

					&:hover,
					&.active {
						span.name {
							color: #333;
							padding-left: 30px;

							&:before {
								background-color: #333;
								width: 15px;
							}
						}


						span.color {
							-webkit-transform: rotate(225deg);
							-ms-transform: rotate(225deg);
							transform: rotate(225deg);
						}
						
					}

					&.active {
						span.color::after {
							content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23c47c5a' class='bi bi-check2' viewBox='0 0 16 16'> <path d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z'/> </svg>");
							transform: rotate(140deg);
							width: 85%;
							/* color: pink; */
						}
					}
				}
			}
		}

		.product {
			position: relative;
			text-decoration: none;
			// -webkit-transform:  translateZ(.25px);
			// -ms-transform:      translateZ(.25px);
			// transform:          translateZ(.25px);

			.containerImage {
				width: 260px;
				height: 373px;
				margin: 0 auto;
				overflow: hidden;
			}

			.productImage {
				/*width: 373px;
				height: 373px;*/
				overflow: hidden;
				will-change: transform;
				-webkit-transition: transform 0.5s ease;
				-moz-transition: transform 0.5s ease;
				-o-transition: transform 0.5s ease;
				transition: transform 0.5s ease;
				width: 100%;
				height: 100%;
				object-fit: cover;

				img {
					width: 100%;
				}
			}

			.productName {
				width: 140px;
				position: absolute;
				right: -20px;
				top: 270px;
				z-index: 2;

				font-family: "Noe Display", serif;
				font-weight: normal;
				font-size: 25px;
				line-height: 30px;
				color: #333;
				text-align: left;
				text-indent: 25px;
				will-change: top;
				-webkit-transition: top 0.5s ease;
				-moz-transition: top 0.5s ease;
				-o-transition: top 0.5s ease;
				transition: top 0.5s ease;

				span {
					background: #333;
					height: 5px;
					width: 32px;
					display: block;
					content: "";
					position: absolute;
					top: 10px;
					left: -10px;
				}

				mark {
					background-color: white;
					padding: 2px 5px;
				}
			}

			.categoryName {
				font-family: "Oswald", sans-serif;
				font-size: 15px;
				color: #333;
				text-transform: uppercase;
				letter-spacing: 1.5px;
				text-align: center;
				margin: 5px;
			}

			&:nth-of-type(3n + 2) {
				margin-top: 130px;
				-webkit-transform: translateZ(0.7px) scale(1);
				-ms-transform: translateZ(0.7px) scale(1);
				transform: translateZ(0.7px) scale(1);
			}

			&:nth-of-type(3n + 2) .productName {
				top: 52px;
			}

			&:hover .productImage {
				-webkit-transform: scale(1.1);
				-ms-transform: scale(1.1);
				transform: scale(1.1);
			}
			&:hover .productName {
				top: 170px;
			}
		}
	}
	@media (max-width: 1600px) {
		.productsList .filters  {
			padding-left: 80px;
		}

		
	}
	@media (max-width: 1200px) {
		.productsList .filters  {
			padding-left: 0px;
		}

		
	}
	@media (max-height: 860px) {
		.productsList .filters .colorFilters {
			// height: 25vh;
			width: 200px;
			// overflow: scroll;

			li {
				margin-left: 10px;
			}
		}

		.productsList.notFeaturedProducts {
			// padding-top: 110px;
			padding-top: 90px;

			h1.pageTitle {
				font-size: 8rem;
				line-height: 6rem;
				opacity: 0.8;

				& > span {
					padding-left: 175px;

					&:before {
						width: 150px;
					}
				}
			}
		}
	}

	@media (max-width: 812px) {
		.productsList {
			&.notFeaturedProducts {
				h1.pageTitle {
					font-size: 100px;
					line-height: 6rem;
					padding-left: 15px;
				}
				.filters {
					position: fixed;
					top: 0;
					bottom: 0;
					z-index: 3;
					background: red;
					display: none;
				}
				.productsContainer {
					padding: 0;
				}
			}

			.product {
				transform: none !important;
				margin-bottom: 90px !important;
				margin-top: 0 !important;

				.containerImage {
					width: 327px !important;
					height: 470px !important;

					.productImage {
						width: 327px !important;
						height: 470px !important;
					}
				}
			}
		}
	}

	@media (max-width: 1200px) {
		.row{
			justify-content: center;
		}
		.productsList {
			
			.product {
				transform: none !important;
				margin-bottom: 90px !important;
				margin-top: 0 !important;

				.containerImage {
					width: 327px !important;
					height: 470px !important;

					.productImage {
						width: 327px !important;
						height: 470px !important;
						object-fit: cover;
					}
				}
			}
		}
	}

	@media (max-width: 1400px) {
		.productsList {
			margin-bottom: 0;

			.product {
				margin-bottom: 130px;
				
				.productName {
					right: calc(50% - 70px);
					top: -30px !important;
					text-indent: 0;
					text-align: center;

					span {
						display: none;
					}
				}
			}
		}
	}

	@media (min-width: 10244px) {
		#bstoneproject.Home {
			.product:nth-of-type(3n + 2) {
				margin-top: 230px;
			}
		}
	}

	@media (min-width: 1400px) {
		.productsList .product:nth-of-type(3n + 2) {
			margin-top: 130px;
		}
	}
</style>
