<template>
  <div id="blocobpage" class="pageContainer">

      <PecasList />
      
  </div>
</template>

<script>
import PecasList from '@/components/PecasList'

export default {
  name: 'blocobpage',
  components: {
    PecasList
  },
  data(){
    return {
        hasFeaturedProducts: false
    }
  }
}
</script>